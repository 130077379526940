"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getState = exports.setState = void 0;
const initial_state_1 = require("./pages/initial-state");
const stateStack = [initial_state_1.initialState];
const setState = (newState, stateUpdated) => {
    stateStack.push(newState);
    stateUpdated(newState);
};
exports.setState = setState;
const getState = () => stateStack[stateStack.length - 1];
exports.getState = getState;
