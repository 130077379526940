"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const components_1 = require("./components");
const simple_components_1 = require("./simple-components");
const utils_1 = require("./utils");
const component = async (state) => (0, simple_components_1.Div)()((0, components_1.Navbar)(state), (0, simple_components_1.Div)({
    class: (0, utils_1.classes)("container", "std-card", "mt-70", "p-3", "max-width-700"),
})((0, simple_components_1.Div)({ class: "row" })((0, simple_components_1.Div)()((0, simple_components_1.Paragraph)()("Thank you so much for signing up! Please ", (0, simple_components_1.A)({ href: "/login" })("login"), " using the email you provided to stripe to enjoy your new benefits :-)")))));
const SignUpSuccessPage = {
    component,
};
exports.default = SignUpSuccessPage;
