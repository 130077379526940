"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authorize = exports.groupBy = exports.attempt = exports.classes = exports.go = exports.getCookie = exports.registerGlobal = exports.getQueryParamFromHash = exports.getGlobalIdentifier = void 0;
const getGlobalIdentifier = (handler) => "window." + handler.name;
exports.getGlobalIdentifier = getGlobalIdentifier;
const getQueryParamFromHash = (paramName) => {
    try {
        const hash = window.location.hash.substring(1);
        const queryParams = new URLSearchParams(hash);
        return queryParams.get(paramName) || null;
    }
    catch (e) {
        // means we are rendering on the backend most likely
        return "";
    }
};
exports.getQueryParamFromHash = getQueryParamFromHash;
const registerGlobal = (handler, ...args) => {
    try {
        window[handler.name] = handler;
        if (args) {
            return "window." + handler.name + `(event, ${args.map((a) => `'${a}'`)})`;
        }
        return "window." + handler.name + "(event)";
    }
    catch (e) {
        // means we are rendering on the backend most likely
        return "";
    }
};
exports.registerGlobal = registerGlobal;
const getCookie = (name) => {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookiesArray = decodedCookie.split(";");
    for (let i = 0; i < cookiesArray.length; i++) {
        let cookie = cookiesArray[i].trim();
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
    return "";
};
exports.getCookie = getCookie;
const go = (route, params = {}, done = () => { }) => {
    if (!Object.keys(params).length) {
        window.location.href = "/" + route;
    }
    else {
        window.location.href =
            "/" +
                route +
                "?" +
                Object.keys(params)
                    .map((key) => key + "=" + params[key])
                    .join("&");
    }
    done();
};
exports.go = go;
const classes = (...args) => {
    return args.filter(Boolean).join(" ");
};
exports.classes = classes;
const attempt = (fn, def) => {
    try {
        return fn();
    }
    catch (e) {
        return def;
    }
};
exports.attempt = attempt;
const groupBy = (array, keyExtractor) => {
    const grouped = {};
    for (const item of array) {
        const key = keyExtractor(item);
        if (!grouped.hasOwnProperty(key)) {
            grouped[key] = [];
        }
        grouped[key].push(item);
    }
    return grouped;
};
exports.groupBy = groupBy;
/**
 *
 * @param authFn returns true or false if the user is authorized
 * @param task executes the task if the authFn returns true
 * @returns false if the user is not authorized, or the resul of task
 */
const authorize = async (authFn, task) => async () => {
    const result = await authFn();
    if (result) {
        return await task();
    }
    return result;
};
exports.authorize = authorize;
