"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const simple_components_1 = require("../../pages/simple-components");
const states_1 = require("../constants/states");
const shortenBillTitle = (title) => {
    const words = title.split(" ");
    if (words.length < 30) {
        return title;
    }
    return words.slice(0, 20).join(" ") + "...";
};
const SearchPageResult = (result) => {
    const hasIntroducedAt = result.introducedAt?.isValid();
    return (0, simple_components_1.Div)({ class: "w-100p" })((0, simple_components_1.A)({
        class: "text-decoration-none d-block",
        href: `/bill/${states_1.stateNameToAbbreviation[result.jurisdiction]}/${result.session}/${result.billNumber}`,
    })((0, simple_components_1.Div)({ class: "std-card" })((0, simple_components_1.Div)({ class: "" })((0, simple_components_1.Div)()((0, simple_components_1.Span)({ class: "fw-bold text-black" })(result.billNumber), (0, simple_components_1.Span)({ class: "text-muted" })(result.jurisdiction)), (0, simple_components_1.If)(hasIntroducedAt)((0, simple_components_1.Div)()((0, simple_components_1.Span)({ class: "text-muted fst-italic" })("Introduced " + result.introducedAt?.format("MMM D, YYYY"))))), (0, simple_components_1.Span)({ class: "text-black" })(shortenBillTitle(result.title)), (0, simple_components_1.Paragraph)({ class: "darken-on-hover text-muted fst-italic mb-0" })("See more..."))));
};
exports.default = SearchPageResult;
