"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function Component(el) {
    return (params = {}) => (...children) => {
        const openTagContent = [
            `${el}`,
            Object.entries(params)
                .reduce((acc, [key, value]) => [...acc, `${key}="${value}"`], [])
                .join(" "),
        ]
            .filter((e) => !!e)
            .join(" ");
        return [`<${openTagContent}>`, `${children.join("")}`, `</${el}>`].join("\n");
    };
}
exports.default = Component;
