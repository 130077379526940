"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    formatBillTitle: (billNumber) => billNumber
        .replace("SCONRES", "Senate Concurrent Resolution")
        .replace("HCONRES", "House Concurrent Resolution")
        .replace("SB", "Senate Bill")
        .replace("HB", "House Bill")
        .replace("HJR", "House Joint Resolution")
        .replace("SJR", "Senate Joint Resolution")
        .replace("HCR", "House Concurrent Resolution")
        .replace("SCR", "Senate Concurrent Resolution")
        .replace("HRES", "House Resolution")
        .replace("SRES", "Senate Resolution")
        .replace("HR", "House Resolution")
        .replace("SR", "Senate Resolution")
        .replace("HM", "House Memorial")
        .replace("SR", "Senate Memorial")
        .replace("S ", "Senate Bill ")
        .replace("H ", "House Bill "),
};
