"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const components_1 = require("./components");
const simple_components_1 = require("./simple-components");
const component = async (state) => (0, simple_components_1.Div)()((0, components_1.Navbar)(state), (0, simple_components_1.Div)({ class: "container std-card mt-70 " })((0, simple_components_1.H1)({ class: "text-center" })("About BriefBill"), (0, simple_components_1.Div)({ class: "row" })((0, simple_components_1.Div)()((0, simple_components_1.Paragraph)()([
    "Hi there! I'm Jeremy, full-time nerd and part-time politics junkie.",
    "I created BriefBill out of a desire to make the laws that govern our lives accessible to regular people, not just lawyers - after all, it's your government, and you have a right to know what it's doing!",
].join(" ")), (0, simple_components_1.Paragraph)()([
    "My goal is to make staying informed about the legislative process as quick and easy as possible.",
    "I've designed BriefBill to make that as easy as possible by using AI to rewrite the arcane laws that govern our lives into something that can be easily read by anyone with a passing interest in what their government's doing. That way, you can stay on top of the issues that matter to you without spending hours sifting through the news.",
].join(" ")), (0, simple_components_1.Paragraph)()([
    "If you'd like to support the site, you can let other people know about it by sharing some of our summaries",
    "and if you'd <i>really</i> like to help out, consider signing up for one of our affordable membership plans - they have additional features too,  like access to key takeaways for each bill, access to previous legislative sessions, and the ability to request summaries for bills BriefBill hasn't summarized yet.",
].join(" ")), (0, simple_components_1.Paragraph)()("I hope you enjoy using BriefBill, and thanks for stopping by!")))));
const AboutPage = {
    template: "app/pages/home.html",
    component,
};
exports.default = AboutPage;
