"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const components_1 = require("./components");
const simple_components_1 = require("./simple-components");
const utils_1 = require("./utils");
const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const res = await fetch("/login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(Object.fromEntries(formData.entries())),
    });
    const data = await res.json();
    if (data.status === "ok") {
        alert("Email sent, check your inbox and follow the link to login!");
    }
    else {
        alert("Login failed");
    }
};
(0, utils_1.registerGlobal)(handleLoginSubmit);
exports.default = (state) => {
    return Promise.resolve((0, simple_components_1.Div)({ class: "d-flex justify-content-center align-items-center h-100" })((0, simple_components_1.Div)({ class: "container std-card" })((0, components_1.TextCenter)((0, simple_components_1.H1)({ class: "mb-3" })("Login"), (0, simple_components_1.Form)({ onsubmit: "window.handleLoginSubmit(event)" })((0, simple_components_1.Label)({ for: "email", class: "form-label" })("Enter your email address below, and we'll send you a link to login. No password required!"), (0, simple_components_1.Input)({
        type: "email",
        class: "form-control mb-3",
        id: "email",
        name: "email",
        placeholder: "name@example.com",
    })(), (0, simple_components_1.Button)({ type: "submit", class: "btn btn-primary" })("Login"))))));
};
