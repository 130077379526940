"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    getJson: async (url) => {
        const res = await fetch(url);
        // probably should do this somewhere else
        // if (res.status === 401) {
        //     window.location.hash = '#login'
        //     return null
        // } else
        if (res.status >= 400) {
            throw new Error(await res.json());
        }
        return await res.json();
    },
    postJson: async (url, body) => {
        const res = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        // probably should do this somewhere else
        // if (res.status === 401) {
        //     window.location.hash = '#login'
        //     return null
        // }
        return (await res.json());
    },
    deleteJson: async (url) => {
        const res = await fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });
        // probably should do this somewhere else
        if (res.status === 401) {
            window.location.hash = "#login";
            // @ts-ignore
            return null;
        }
        return (await res.json());
    },
};
